<template>
  <div>
    <h3 class="mb-4">{{ $t('operationManagement.tabs.balanceAlignment') }}</h3>
    <Table
      v-loading="tasksLoading"
      :columns="columns"
      :data="tasks"
      border
      rounded
      hover
      @row-click="($event) => handleTableRowClick(tasks[$event])"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + PAGE_LIMIT * (currentPage - 1) }}
      </template>
      <template #[`cell-${TABLE_HEADER.TASK_STATUS}`]="{ rowData: task }">
        <div class="d-flex">
          <TaskTag :task="task" @updateTask="handleTaskUpdate">
            <template #task-snooze-modal-subtitle>
              <RestaurantSupplierSubtitle
                v-if="task"
                :supplier-name="getSupplierName(task.data.supplierId)"
                :customer-name="currentTenant.name"
              />
            </template>
          </TaskTag>
          <TruncatedText class="w-75 px-2">
            {{ task.data.comment }}
          </TruncatedText>
        </div>
      </template>
      <template #[`cell-${TABLE_HEADER.SUPPLIER}`]="{ rowData: task }">
        <div v-if="task && suppliers">
          <TruncatedText>
            {{ getSupplierName(task.data.supplierId) }}
          </TruncatedText>
        </div>
        <div v-else class="placeholder-glow">
          <span :class="`placeholder bg-secondary rounded col-9`" />
        </div>
      </template>
    </Table>
    <div class="d-flex justify-content-end my-3">
      <el-pagination
        v-if="tasksTotalCount > PAGE_LIMIT"
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="PAGE_LIMIT"
        :page-count="Math.ceil(tasksTotalCount / PAGE_LIMIT)"
        :total="tasksTotalCount"
      />
    </div>
  </div>
</template>

<script>
import { isNil } from 'ramda';
import { ref, computed } from 'vue';

import { RestaurantSupplierSubtitle, usePatchTask, useActivateTask } from '@/modules/tasks';
import { Table, TruncatedText } from '@/modules/core';
import { TaskTag } from '@/modules/tasks/taskTag';
import { useSuppliersNew } from '@/modules/suppliers';
import { useTenancy } from '@/modules/auth';

import { useTasks } from './composition';

const TABLE_HEADER = {
  TASK_STATUS: 'taskStatus',
  SUPPLIER: 'supplier',
};

const PAGE_LIMIT = 25;

export default {
  components: {
    RestaurantSupplierSubtitle,
    Table,
    TruncatedText,
    TaskTag,
  },
  setup() {
    const { currentTenant } = useTenancy();
    const currentPage = ref(1);

    const pagination = computed(() => ({
      limit: PAGE_LIMIT,
      offset: (currentPage.value - 1) * PAGE_LIMIT,
    }));

    const {
      tasks: tasks,
      totalCount: tasksTotalCount,
      loading: tasksLoading,
      refetch: tasksRefetch,
    } = useTasks(() => ({
      businessId: currentTenant.value.id,
      completed: false,
      domain: 'reconciliation',
      type: 'balanceAlignment',
      ...pagination.value,
    }));

    const { suppliers } = useSuppliersNew();

    const { patchTask } = usePatchTask();
    const { activeTask } = useActivateTask();

    return {
      currentTenant,
      TABLE_HEADER,
      PAGE_LIMIT,
      tasks,
      tasksTotalCount,
      tasksLoading,
      tasksRefetch,
      patchTask,
      activeTask,
      suppliers,
      currentPage,
      activeRowActionsIndex: ref(-1),
    };
  },
  computed: {
    columns() {
      return [
        {
          header: '#',
          key: 'index',
          width: '20px',
          customClass: 'ps-0',
        },
        {
          header: this.$t('operationManagement.taskTable.table.headers.taskStatus'),
          key: TABLE_HEADER.TASK_STATUS,
          width: '33rem',
        },
        {
          header: this.$t('operationManagement.taskTable.table.headers.supplier'),
          key: TABLE_HEADER.SUPPLIER,
          width: '20rem',
        },
      ];
    },
  },
  methods: {
    handleTableRowClick(task) {
      this.$router.push({ name: 'balanceAlignmentTask', params: { taskId: task.id } });
    },
    async handleTaskUpdate(updateData) {
      try {
        this.tasksLoading = true;
        await this.patchTask({
          taskId: updateData.task.id,
          patchParams: {
            data: { ...updateData.task.data, comment: updateData.comment },
          },
        });
        if (!isNil(updateData.activeAt)) {
          await this.activeTask({
            taskId: updateData.task.id,
            activeParams: {
              activeAt: updateData.activeAt,
            },
          });
        }
        this.tasksRefetch();
      } finally {
        this.tasksLoading = false;
      }
    },
    getSupplierName(supplierId) {
      return this.suppliers.find(({ id }) => id === supplierId)?.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
</style>
