<template>
  <div>
    <h3 class="mb-4">{{ title }}</h3>
    <Table
      v-loading="tasksLoading || tasksUpdating"
      :columns="columns"
      :data="tableData"
      border
      rounded
      hover
      custom-class="root-table"
      @row-click="handleRowClick($event)"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + TASKS_DEFAULT_LIMIT * (currentPage - 1) }}
      </template>
      <template #[`cell-${TABLE_HEADER.TASK_STATUS}`]="{ rowData: { task, reconciliation } }">
        <div class="d-flex">
          <TaskTag :task="task" @updateTask="handleTaskUpdate">
            <template #task-snooze-modal-subtitle>
              <RestaurantSupplierSubtitle
                v-if="reconciliation"
                :supplier-name="getSupplierName(reconciliation.supplierId)"
                :customer-name="currentTenant.name"
              />
            </template>
          </TaskTag>
          <TruncatedText class="w-75 px-2">
            {{ task.data.comment }}
          </TruncatedText>
        </div>
      </template>
      <template #[`cell-${TABLE_HEADER.SUPPLIER}`]="{ rowData: { reconciliation } }">
        <div v-if="reconciliation && suppliers">
          <TruncatedText>
            {{ getSupplierName(reconciliation.supplierId) }}
          </TruncatedText>
        </div>
        <div v-else class="placeholder-glow">
          <span :class="`placeholder bg-secondary rounded ${statusPlaceholderWidth}`" />
        </div>
      </template>
      <template #[`cell-${TABLE_HEADER.BILLING_PERIOD}`]="{ rowData: { reconciliation } }">
        <div v-if="reconciliation">
          {{ getReconciliationPeriod(reconciliation) }}
        </div>
        <div v-else class="placeholder-glow">
          <span :class="`placeholder bg-secondary rounded ${statusPlaceholderWidth}`" />
        </div>
      </template>
      <template #[`cell-${TABLE_HEADER.PAYMENT_DUE_DATE}`]="{ rowData: { reconciliation } }">
        <div v-if="reconciliation">
          {{ formatDate(reconciliation.paymentDueDate) }}
        </div>
        <div v-else class="placeholder-glow">
          <span :class="`placeholder bg-secondary rounded ${statusPlaceholderWidth}`" />
        </div>
      </template>
      <template #[`cell-${TABLE_HEADER.RECONCILIATION_STATUS}`]="{ rowData: { reconciliation }, rowIndex }">
        <ReconciliationStatus
          v-if="reconciliation"
          :is-admin="isAdmin"
          :reconciliation-status="reconciliation.status"
          :reconciliation-id="reconciliation.id"
          :status-override="reconciliation.statusOverride"
          :business-id="currentTenant.id"
          :supplier="getSupplier(reconciliation.supplierId)"
          :show="activeReconciliationStatus === rowIndex"
          @open="activeReconciliationStatus = rowIndex"
          @close="activeReconciliationStatus = -1"
        />
        <div v-else class="placeholder-glow">
          <span :class="`placeholder bg-secondary rounded ${statusPlaceholderWidth}`" />
        </div>
      </template>
    </Table>

    <div class="d-flex justify-content-end my-3">
      <el-pagination
        v-if="tasksTotalCount > TASKS_DEFAULT_LIMIT"
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="TASKS_DEFAULT_LIMIT"
        :page-count="Math.ceil(tasksTotalCount / TASKS_DEFAULT_LIMIT)"
        :total="tasksTotalCount"
      />
    </div>
  </div>
</template>

<script>
import { isNil } from 'ramda';
import { DateTime } from 'luxon';
import { ref, computed } from 'vue';

import { RestaurantSupplierSubtitle, usePatchTask, useActivateTask } from '@/modules/tasks';
import { Table, TruncatedText } from '@/modules/core';
import { useTenancy, useUser } from '@/modules/auth';
import { TaskTag } from '@/modules/tasks/taskTag';
import { useSuppliersNew } from '@/modules/suppliers';
import { options } from '@/locale/dateConfig';

import { useReconciliations, useTasks } from './composition';

const TABLE_HEADER = {
  TASK_STATUS: 'taskStatus',
  SUPPLIER: 'supplier',
  BILLING_PERIOD: 'billingPeriod',
  PAYMENT_DUE_DATE: 'paymentDueDate',
  RECONCILIATION_STATUS: 'reconciliationStatus',
};

export default {
  components: {
    RestaurantSupplierSubtitle,
    Table,
    TruncatedText,
    TaskTag,
    ReconciliationStatus: () => import('@/modules/reconciliation/reconciliationStatus/ReconciliationStatus'),
  },
  props: {
    title: { type: String, default: () => '' },
  },
  setup() {
    const currentPage = ref(1);
    const { currentTenant } = useTenancy();
    const { isAdmin } = useUser();

    const { suppliers } = useSuppliersNew();

    const TASKS_DEFAULT_LIMIT = 25;

    const pagination = computed(() => ({
      limit: TASKS_DEFAULT_LIMIT,
      offset: (currentPage.value - 1) * TASKS_DEFAULT_LIMIT,
    }));

    const {
      tasks: tasks,
      totalCount: tasksTotalCount,
      loading: tasksLoading,
      refetch: tasksRefetch,
    } = useTasks(() => ({
      businessId: currentTenant.value.id,
      completed: false,
      domain: 'reconciliation',
      type: 'handleReconciliation',
      ...pagination.value,
    }));

    const { patchTask } = usePatchTask();
    const { activeTask } = useActivateTask();

    const reconciliationIds = computed(() => (tasks.value ?? []).map(({ data }) => data.reconciliationId));

    const { reconciliations, loading: reconciliationsLoading } = useReconciliations(
      computed(() => ({
        businessId: currentTenant.value.id,
        ids: reconciliationIds.value,
      }))
    );

    return {
      tasksUpdating: ref(false),
      isAdmin,
      suppliers,
      TABLE_HEADER,
      tasks,
      tasksTotalCount,
      tasksRefetch,
      patchTask,
      activeTask,
      reconciliations,
      reconciliationsLoading,
      TASKS_DEFAULT_LIMIT,
      currentPage,
      tasksLoading,
      statusPlaceholderWidth: Math.random() > 0.5 ? 'col-9' : 'col-7',
      currentTenant,
      activeReconciliationStatus: ref(-1),
      activeRowActionsIndex: ref(-1),
    };
  },

  computed: {
    tableData() {
      return (this.tasks ?? []).map((task) => ({
        task,
        reconciliation: this.reconciliations.find(({ id }) => id === task.data.reconciliationId),
      }));
    },
    columns() {
      return [
        {
          key: 'index',
          width: '42px',
        },
        {
          header: this.$t('operationManagement.taskTable.table.headers.taskStatus'),
          key: TABLE_HEADER.TASK_STATUS,
          width: '340px',
        },
        {
          header: this.$t('operationManagement.taskTable.table.headers.supplier'),
          key: TABLE_HEADER.SUPPLIER,
          width: '215px',
        },
        {
          header: this.$t('operationManagement.taskTable.table.headers.billingPeriod'),
          key: TABLE_HEADER.BILLING_PERIOD,
          width: '130px',
        },
        {
          header: this.$t('operationManagement.taskTable.table.headers.paymentDueDate'),
          key: TABLE_HEADER.PAYMENT_DUE_DATE,
          width: '120px',
        },
        {
          header: this.$t('operationManagement.taskTable.table.headers.reconciliationStatus'),
          key: TABLE_HEADER.RECONCILIATION_STATUS,
          width: '155px',
        },
      ];
    },
  },
  methods: {
    async handleTaskUpdate(updateData) {
      try {
        this.tasksUpdating = true;
        await this.patchTask({
          taskId: updateData.task.id,
          patchParams: {
            data: { ...updateData.task.data, comment: updateData.comment },
          },
        });
        if (!isNil(updateData.activeAt)) {
          await this.activeTask({
            taskId: updateData.task.id,
            activeParams: {
              activeAt: updateData.activeAt,
            },
          });
        }
        await this.tasksRefetch();
      } finally {
        this.tasksUpdating = false;
      }
    },
    handleRowClick(rowIndex) {
      this.$router.push({ name: 'reconciliationTask', params: { taskId: this.tableData[rowIndex].task.id } });
    },
    getSupplierName(supplierId) {
      return this.suppliers.find(({ id }) => id === supplierId)?.name ?? '';
    },
    getSupplier(supplierId) {
      return this.suppliers.find(({ id }) => id === supplierId);
    },
    getReconciliationPeriod({ periodStart, periodEnd }) {
      if (periodStart === periodEnd)
        return new Date(periodStart).toLocaleDateString(this.$i18n.locale, options.twoDigits);
      return new Date(periodEnd).toLocaleDateString(this.$i18n.locale, {
        month: 'short',
        year: '2-digit',
      });
    },
    formatDate(date) {
      return date ? DateTime.fromISO(date).toUTC().toFormat('dd.MM.yy') : '-';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.root-table {
  .actions-btn {
    &:hover {
      background: $secondary;
    }
    &.active {
      visibility: visible;
    }
  }

  tr {
    .actions-btn {
      visibility: hidden;
    }

    &:hover .actions-btn {
      visibility: visible;
    }
  }
}
</style>
